

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
  font-family: 'impactregular';
  src: url('../assets/fonts/unicode.impact-webfont.woff2') format('woff2'),
       url('../assets/fonts/unicode.impact-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* general settings */

html, body {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* typography */
/* set font families */
.Title, .SubTitle, .TitleInverted, .SubTitleInverted, .ButtonText {
  font-family: 'impactregular', 'sans-serif';
}

/* set colors */
.Title, .SubTitle, p{
  color: white;
}

.TitleInverted, .SubTitleInverted,  .PInverted, .ButtonContainer{
  color: #5956A1;
}

/* set sizes */
.Title, .TitleInverted{
  font-size: 2em;
}

.SubTitle, .SubTitleInverted, .ButtonText {
  font-size: 1.5em; 
}

/* containers */
.Container {
  background-color: white;
  padding: 10px;
}

/* background colors */
body {
  background: #5956A1;
}

.ButtonContainer, .InvertedButtonContainer {
  width: auto;
  background: white;
  margin: 10px;
  padding: 10px;
  border: solid 3px white;
  cursor: pointer;
}

.InvertedButtonContainer {
  background: #5956A1;
  color: white;
}

 .ButtonContainer:hover {
  background: #5956A1;
  color: white;
} 

.InvertedButtonContainer:hover {
  background: white;
  color: #5956A1;
} 

.ButtonText {
  text-transform: uppercase;
}


/* images */
.ResponsiveImage {
  width: 100%;
}


/* CUSTOM */



.Footer {
    background-color: white;
    border-top: 1px solid white;
    /* padding: 20px; */
    /* padding-bottom: 0px; */
    /* padding-left: 40px; */
    /* position: fixed; */
    left: 0;
    bottom: 0;
    height: auto;
    width: 100%;
}


.PartnerImages {
  /* width: 100%; */
  /* max-width: 100px; */
  height: 100px;
  width: 70%;
  float: left;
  object-fit:scale-down;
  /* object-fit: cover; */
}

.socials {
  width: 40px;
  transform: translate(-40px, -12px);
  float: right;
  margin-right: 20px;
}

.headerImage {
  width: 500px;
  height: 100%;            
  position: absolute;
  top: 0;
  left: calc(50% - 250px);
  
}

.HeaderContainer{
  width: 100%;
  height: 500px;
  position: relative;
}


.MessageContainer {
  z-index: 9;
  margin-left: 20px;
  top: 0;
  left: auto;
  right: 0px;
  top: 20px;
  opacity: 0.95;
}

.Message {
  background: white;  
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-family: 'impactregular', 'sans-serif';
  color: #5956A1;
  font-size: 1.2em; 
  border: 3px solid #5956A1;
}

.MenuContainer {
  text-align: right;
  z-index: 10;
}

.Menu {
  color: white;
  font-size: 1px;
  transform: translate(-30px, 10px) scale(40);
}

.NotMobie {
  display: inherit;
}

.OnlyMobile {
  display:none;
}

.drop {
  border: solid 3px white;
  padding: 15px;
}


@media screen and (max-width: 768px) {
  

  .Message {
    padding-left: 30px;
  }
  
  .HeaderContainer{
    width: 100%;
    height: auto;
    position: relative;
  }

  .headerImage {
    width: 100%;
    height: 100%;            
    position: relative;
    top: 0;
    left: 0;
  }  
  .OnlyMobile {
    display:inherit;
  }
  .NotMobie {
    display: none;
  }
}

.MemeCard {
  background: white;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px)  {
  .mobile-break { display: none; }
  .MenuContainer {
    display: none;
  }
}

